import { property } from 'lodash';
import { Utils, utils } from '../utils/workerUtils';
import * as VideoGallerySDK from '@wix/video-gallery-sdk';
import AppSettings from '@wix/photography-client-lib/dist/src/api/appSettings';
import Consts from '@wix/photography-client-lib/dist/src/utils/consts';
import { serverItemsToProGallery } from '@wix/pro-gallery-items-formatter/dist/src/server';
import { experimentsWrapper } from '@wix/photography-client-lib/dist/src/sdk/experimentsWrapper';
import { GalleryItem, GALLERY_CONSTS } from 'pro-gallery/lib';
import { resizeMediaUrl } from '@wix/photography-client-lib/dist/src/item/itemResizer';

export default class ProGalleryStore {
  constructor(
    context,
    wixCodeApi,
    platformServices,
    scopedGlobalSdkApis,
    APP_DEFINITION_ID,
    GALLERY_WIDGET_ID,
    isArtStore,
    setNewStyleParamsCallback,
    setItemsCallback,
    setDirectfullscreenItemCallBack,
  ) {
    this.context = context;
    this.wixCodeApi = wixCodeApi;
    this.directfullscreenItem = 'init';
    this.SSRWorkerLog = ['constructor'];
    this.APP_DEFINITION_ID = APP_DEFINITION_ID;
    this.GALLERY_WIDGET_ID = GALLERY_WIDGET_ID;
    this.isArtStore = isArtStore;
    this.getGalleryDataFromServer = this.getGalleryDataFromServer.bind(this);
    this.getItemsFromServer = this.getItemsFromServer.bind(this);
    this.setItems = this.setItems.bind(this);
    this.getItems = this.getItems.bind(this);
    this.setPageName = this.setPageName.bind(this);
    this.formatServerGalleryResponse = this.formatServerGalleryResponse.bind(
      this,
    );
    this.viewMode = Utils.parseViewMode(this.wixCodeApi.window.viewMode);
    this.baseUrl = Utils.getBaseUrl(this.wixCodeApi, this.viewMode);
    this.appSettingsBaseUrl =
      this.viewMode === GALLERY_CONSTS.viewMode.PREVIEW ? '' : this.baseUrl; // to debug locally, for PREVIEW sometimes need to put 'https://editor.wix.com',
    this.appSettings = new AppSettings(
      this.context.compId,
      this.context.instance,
      this.viewMode,
      this.appSettingsBaseUrl,
    );
    this.dataSavedState =
      this.viewMode === GALLERY_CONSTS.viewMode.PREVIEW
        ? Consts.dataSavedState.SAVED
        : Consts.dataSavedState.PUBLISHED;
    this.scopedGlobalSdkApis = scopedGlobalSdkApis;
    this.pgRenderStart = Date.now();
    this.setNewStyleParamsCallback = setNewStyleParamsCallback;
    this.setItemsCallback = setItemsCallback;
    this.setDirectfullscreenItemCallBack = setDirectfullscreenItemCallBack;
    this.setDirectfullscreenOnce = false;
    this.ITEMS_BATCH_SIZE = 50;
    this.INITIAL_ITEMS_BATCH_SIZE = 50;

    try {
      this.createFedopsLogger(platformServices);
      this.fedopsLogger.appLoadStarted();
      this.isAppLoadStartReported = true;
      console.log('pro-gallery-santa-wrapper gallery widget started to load');
    } catch (e) {
      console.error('Cannot create fedops logger', e);
      this.fedopsLogger = {
        appLoaded: () => {},
        appLoadStarted: () => {},
      };
      this.isAppLoadStartReported = true;
    }
  }

  createFedopsLogger = platformServices => {
    const fedOpsLoggerFactory = platformServices.fedOpsLoggerFactory;
    this.fedopsLogger = fedOpsLoggerFactory.getLoggerForWidget({
      appId: this.APP_DEFINITION_ID,
      widgetId: this.GALLERY_WIDGET_ID,
    });
  };

  tryToReportAppLoaded = (isSSR = false) => {
    if (this.isAppLoadStartReported && !this.isAppLoadedReported) {
      try {
        if (isSSR) {
          this.fedopsLogger.appLoaded();
        } else {
          this.fedopsLogger.appLoaded(this.getFedopsCustomParams());
        }
        this.isAppLoadedReported = true;
        console.log('pro-gallery-santa-wrapper gallery widget loaded');
      } catch (e) {
        if (utils.isVerbose()) {
          console.log('cant run fedopsLogger.appLoaded()', e);
        }
      }
    }
  };

  getFedopsCustomParams() {
    const customParams = {
      customParams: JSON.stringify({
        gallery_id: this.galleryId || 'galleryId is undefined',
      }),
    };
    return customParams;
  }

  setSettings(settings) {
    this.settings = { ...this.settings, ...settings };
  }

  static getSiteUrl(api) {
    return api.location.baseUrl;
  }

  static getPageUrl(api) {
    const url = api.location.url.split('?')[0];
    return url;
  }

  createShareUrl(itemId, api) {
    if (this.isArtStore) return null; // remove when art-store is using the new share methods
    const pageUrl = ProGalleryStore.getPageUrl(api);
    return (
      pageUrl +
      '?pgid=' +
      this.context.compId.replace('comp-', '') +
      '-' +
      itemId
    );
  }

  static getCurrentDirectFullscreenUrl(api) {
    const pageUrl = ProGalleryStore.getPageUrl(api);
    const query = api.location.query;
    const pgid = query && query.pgid;
    if (pgid) {
      return pageUrl + '?pgid=' + pgid;
    } else {
      return null;
    }
  }

  static getTranslations(locale = 'en') {
    // only under scope `pro-gallery-viewer`
    return fetch(
      `https://static.parastorage.com/services/pro-gallery-statics/2.854.0/assets/locale/pro-gallery/Gallery/Gallery_${locale}.json`,
    )
      .then(res => res.json())
      .catch(() => {
        return {};
      });
  }

  async getAppSettings() {
    try {
      const appSettings = await this.appSettings.get(this.dataSavedState);
      if (appSettings && appSettings.galleryId) {
        Object.assign(this, { ...appSettings });
        this.appSettingsData = appSettings;
        return appSettings;
      } else return undefined;
    } catch (error) {
      console.error('Failed to get settings: ' + error);
    }
  }

  async getGallerySettings(isSSR = false) {
    try {
      await this.getGalleryDataFromServer({ from: 0, options: { isSSR } }).then(
        response => {
          if (response && response.galleryId) {
            const { galleryId, dateCreated } = response;
            const { settings } = response.pageResponse;
            this.galleryId = galleryId;
            this.dateCreated = dateCreated;
            this.setSettings(settings);
            return { galleryId, dateCreated, settings: this.settings };
          } else {
            return undefined;
          }
        },
      );
    } catch (error) {
      console.error('Failed to get settings: ' + error);
    }
  }

  formatServerGalleryResponse(serverGalleryResponseData, settings) {
    const gallery =
      serverGalleryResponseData && serverGalleryResponseData.gallery;
    return !!gallery
      ? {
          dateCreated: gallery.created,
          galleryId: gallery.id,
          pageResponse: {
            totalItemsCount: gallery.totalItemsCount,
            settings: JSON.stringify(settings),
            items: this.setPageName(serverItemsToProGallery(gallery.items)),
          },
        }
      : {};
  }

  setPageName(proGalleryItems) {
    const sitePages = this.wixCodeApi.site.getSiteStructure().pages;
    return proGalleryItems.map(item => {
      try {
        if (
          'metaData' in item &&
          'link' in item.metaData &&
          'data' in item.metaData.link &&
          'type' in item.metaData.link.data &&
          item.metaData.link.data.type === 'PageLink'
        ) {
          const page = sitePages.find(_page =>
            item.metaData.link.data.pageId.endsWith(_page.id),
          );
          if (!!page && 'name' in page) {
            item.metaData.link.data.pageName = page.name;
          }
        }
      } catch (error) {}
      return item;
    });
  }

  getMultiLangFields() {
    const currentShortLang = this.wixCodeApi.window.multilingual
      .currentLanguage;
    const currentLang = this.wixCodeApi.window.multilingual.siteLanguages.filter(
      lang => lang.languageCode === currentShortLang,
    );
    if (currentLang.length > 0) {
      return {
        isPrimaryLanguage: currentLang[0].isPrimaryLanguage,
        lang: currentShortLang,
        locale: currentLang[0].locale,
      };
    }

    return null;
  }

  getMultiLangHeader() {
    const fields = this.getMultiLangFields();
    const { instanceId } = this.context;
    const header = fields
      ? `${fields.lang}|${
          fields.locale
        }|${fields.isPrimaryLanguage.toString()}|${instanceId}`
      : '';
    this.SSRWorkerLog.push('Multilingual header: ' + header);
    return header;
  }

  getLangQuery() {
    const fields = this.getMultiLangFields();
    const query = fields ? `${fields.lang}${fields.locale}` : '';
    return query;
  }

  async getGalleryDataFromServer({
    from,
    batchSize = this.ITEMS_BATCH_SIZE,
    options = {},
    settings = null,
  }) {
    const { isSSR = false } = options;
    const to = from + batchSize;
    const { compId, instanceId, instance } = this.context;
    let { externalId } = this.context;
    if (!externalId) {
      // externalId of a new site before publish in Preview is "", so we protect here undefined and ""
      externalId = '00000000-0000-0000-0000-000000000000';
    }
    await Utils.verifyExperiments(this.scopedGlobalSdkApis);
    if (
      experimentsWrapper.getExperimentBoolean(
        'specs.pro-gallery.appSettings',
      ) &&
      !this.isArtStore
    ) {
      settings = settings
        ? settings
        : this.appSettingsData || (await this.getAppSettings());
      const limit = to - from;
      const itemsUrl =
        this.galleryId &&
        `${this.baseUrl}/pro-gallery-webapp/v1/galleries/${
          this.galleryId
        }?offset=${from}&limit=${limit}&externalId=${externalId}&state=${
          this.dataSavedState
        }&lang=${this.getLangQuery()}`;
      if (itemsUrl) {
        try {
          const response = await fetch(itemsUrl, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: instance,
              'x-wix-linguist': this.getMultiLangHeader(),
            },
          });
          const data = await response.json();
          return this.formatServerGalleryResponse(data, settings);
        } catch (error) {
          console.error('Failed to get data from (new) server: ', error);
        }
      }
    }
    const itemsUrl = this.addSsrUrlParams(
      isSSR,
      `${this.baseUrl}/_api/pro-gallery-webapp/v1/gallery/${instanceId}/items/from/${from}/to/${to}?compId=${compId}&externalId=${externalId}`,
    );

    if (utils.isVerbose()) {
      console.log('Getting items from server', from, to, itemsUrl);
    }
    return fetch(itemsUrl, {
      headers: {
        'x-wix-linguist': this.getMultiLangHeader(),
      },
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 404 && isSSR) {
          if (utils.isVerbose()) {
            console.log('first publish');
          }
          return [];
        } else {
          throw new Error(res.statusText);
        }
      })
      .catch(e => {
        console.error('Could not get items from (old) server', e);
        return [];
      });
  }

  getConnectedProvidersFromServer(wixInstance = false) {
    const baseUrl = Utils.getBaseUrl(this.wixCodeApi, this.viewMode);
    let { instance } = this.context;
    if (wixInstance) {
      instance = wixInstance;
    }
    const url = `${baseUrl}/_api/pro-gallery-webapp/v1/store/providers?instance=${instance}`;
    return fetch(url).then(response => {
      return response.json();
    });
  }

  getQueryParams() {
    return this.wixCodeApi.location.query;
  }

  static isValidUUID(itemId) {
    const regEx = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return !!itemId.match(regEx);
  }

  async getItemByIdFromServer(itemId, isSSR) {
    if (!ProGalleryStore.isValidUUID(itemId)) {
      return {
        item: 'itemId: ' + itemId + 'is not valid',
      };
    }
    const baseUrl = Utils.getBaseUrl(this.wixCodeApi, this.viewMode);
    const { instance } = this.context;
    let galleryId;
    if (!this.galleryId) {
      const settings = await this.getAppSettings(isSSR);
      galleryId = settings && settings.galleryId;
      if (!settings) {
        await this.getGallerySettings(isSSR);
      }
    }
    galleryId = this.galleryId;
    const itemsUrl = `${baseUrl}/pro-gallery-webapp/v1/galleries/${galleryId}/items/${itemId}?state=${this.dataSavedState}`;
    return fetch(itemsUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: instance,
        'x-wix-linguist': this.getMultiLangHeader(),
      },
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else {
          return {
            item:
              'server response was not 200' +
              itemsUrl +
              'the response Status:' +
              res.status,
          };
        }
      })
      .catch(e => {
        console.error('Could not get item by id from the server', e);
        return {
          item: 'some error when requesting from server' + itemsUrl + e.message,
        };
      });
  }

  getItemsFromServer(from) {
    return this.getGalleryDataFromServer({ from }).then(res => {
      return res.pageResponse.items;
    });
  }

  addSsrUrlParams(isSSR, itemsUrl) {
    if (isSSR) {
      itemsUrl += '&petri_ovr=specs.SkipCachePopulationSpec:true';
    }
    return itemsUrl;
  }

  navigateToLink(itemData) {
    const link = property('dto.metaData.link')(itemData);
    try {
      if (link) {
        if (link.type === 'web') {
          this.wixCodeApi.location.to(link.url);
        } else if (link.type === 'page') {
          try {
            const pageUrl = this.wixCodeApi.site
              .getSiteStructure()
              .pages.find(page => page.id === link.url).url;
            this.wixCodeApi.location.to(pageUrl);
          } catch (e) {
            console.warn('Cannot navigate to page', e);
          }
        } else if (link.type === 'wix') {
          const linkData = property('dto.metaData.link.data')(itemData);
          if (linkData) {
            const externalUrl = this.wixCodeApi.location.getExternalUrl(
              linkData,
            );
            if (!externalUrl) {
              this.wixCodeApi.location.navigateTo(linkData);
            } else {
              console.error('this should have been handled by <a href>');
            }
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  getVideoMediaId(item) {
    if (
      item.metaData.videoUrl &&
      item.metaData.videoUrl.includes('wix:video://')
    ) {
      return item.metaData.videoUrl.split('/')[3] || item.itemId;
    } else {
      return item.mediaUrl;
    }
  }

  getItemWithVideoUrls(item) {
    const videoId = this.getVideoMediaId(item);
    return VideoGallerySDK.getVideoURLs(videoId)
      .then(qualities => {
        item.metaData.qualities = qualities
          .filter(quality => quality.type === 'MP4')
          .map(qualityObject => {
            const url = qualityObject.url;
            const quality = qualityObject.quality;
            const height = parseInt(quality);
            const width = Math.floor(
              (height * item.metaData.width) / item.metaData.height,
            );
            return {
              url,
              height,
              width,
              quality,
              formats: ['mp4'],
            };
          });
        item.metaData.videoUrl = undefined;
        if (
          experimentsWrapper.getExperimentBoolean('specs.pro-gallery.hlsVideos')
        ) {
          const hls = qualities.filter(quality => quality.type === 'HLS');
          if (hls.length > 0) {
            item.metaData.videoUrl = hls[0].url;
          }
        } else {
          item.metaData.ghostItem = true; // see below. this is here just for the experiment to have an "else" and not stay placeholder.
        }
        return item;
      })
      .catch(e => {
        item.metaData.ghostItem = true; // item does not exist but is still in the pg database
        return item;
      });
  }

  getItemsWithWixVideosUrls(items) {
    const result = items.map(item => {
      if (this.isUnreadyVideoFile(item)) {
        return this.getItemWithVideoUrls(item);
      } else {
        return Promise.resolve(item);
      }
    });

    return Promise.all(result);
  }

  replaceByPlaceHolder(item) {
    return {
      itemId: item.itemId,
      mediaUrl: item.metaData.posters[0].url,
      orderIndex: 0,
      metaData: {
        ...item.metaData,
        type: 'image',
        isVideoPlaceholder: true,
        height: item.metaData.posters[0].height,
        width: item.metaData.posters[0].width,
      },
    };
  }

  isUnreadyVideoFile(item) {
    return (
      !item.metaData.ghostItem &&
      ((item.metaData.videoUrl &&
        item.metaData.videoUrl.includes('wix:video://')) ||
        (item.metaData.type === 'video' &&
          item.mediaUrl &&
          !item.metaData.videoUrl))
    );
  }

  addItemProperties(item) {
    let convertedToPlaceholder = false;
    if (this.isUnreadyVideoFile(item)) {
      item = this.replaceByPlaceHolder(item);
      convertedToPlaceholder = true;
    }
    let directLink = {
      url: undefined,
      target: undefined,
    };
    if (item.metaData && item.metaData.link) {
      const link = item.metaData.link;
      if (link.type === 'web' && typeof link.url === 'string') {
        const isExternal =
          link.url.slice(0, 4) === 'http' || link.url.slice(0, 2) === '//';
        if (isExternal || this.wixCodeApi.seo.isInSEO()) {
          // bots need to see inner pathing e.g. "/subpage", they can only see it if its on the <a> tag JIRA PG-193
          directLink = {
            url: link.url,
            target: '_blank',
          };
        }
      } else if (link.type === 'wix') {
        const linkData = link.data;
        if (linkData) {
          try {
            const externalUrl = this.wixCodeApi.location.getExternalUrl(
              linkData,
            );
            if (externalUrl) {
              directLink = {
                url: externalUrl,
                target: '_blank', // if externalLink, the target should be '_blank'. In old galleries we have some items with target '_self' as in the past users could choose if they want the links to open in new tab or not. But now all the external links should be opened in a new tab.
              };
            } else if (
              !!linkData.pageName &&
              linkData.target === '_blank' &&
              linkData.type === 'PageLink'
            ) {
              const pageUrl = this.wixCodeApi.site
                .getSiteStructure()
                .pages.find(page => page.name === linkData.pageName);
              const url =
                pageUrl &&
                pageUrl.url &&
                this.wixCodeApi.location.baseUrl &&
                this.wixCodeApi.location.baseUrl + pageUrl.url;
              directLink = !!url
                ? {
                    url: url,
                    target: '_blank',
                  }
                : directLink;
            }
          } catch (e) {
            console.error(e);
          }
        }
      }
    }
    item.directLink = directLink;
    const itemId = () => {
      return item.id || item.photoId || item.itemId;
    };
    item.directShareLink = experimentsWrapper.getExperimentBoolean(
      'specs.pro-gallery.itemDeeplinks',
    )
      ? this.createShareUrl(itemId(), this.wixCodeApi)
      : null;
    return { item, convertedToPlaceholder };
  }

  setItems(items, setWixCodeItemsCallback) {
    this.shouldGetVideoUrls = false;
    let itemsToSet;
    if (items && items.length >= 0) {
      itemsToSet = items.map(item => {
        const {
          item: resItem,
          convertedToPlaceholder,
        } = this.addItemProperties(item);
        if (convertedToPlaceholder) {
          this.shouldGetVideoUrls = true;
        }
        return resItem;
      });
    } else {
      console.error('corrupt items', items, 'returning old items instead');
      itemsToSet = this.items;
    }
    if (this.itemsSrc === 'wixCode') {
      setWixCodeItemsCallback(itemsToSet);
    } else {
      this.items = itemsToSet;
    }
    if (this.shouldGetVideoUrls) {
      this.buildVideoUrlsAndSet(items, setWixCodeItemsCallback);
    }
  }

  buildVideoUrlsAndSet(items, setWixCodeItemsCallback) {
    this.getItemsWithWixVideosUrls(items).then(itemsWithVideoUrls => {
      const finalizedItems = itemsWithVideoUrls.map(item => {
        const { item: finalizedItem } = this.addItemProperties(item);
        return finalizedItem;
      });
      if (this.itemsSrc === 'wixCode') {
        setWixCodeItemsCallback(finalizedItems);
      } else {
        this.items = finalizedItems;
        this.setItemsCallback();
      }
    });
  }

  getItems() {
    return this.items || [];
  }

  itemToGalleryItem(item) {
    const galleryItem = new GalleryItem({
      dto: item,
      watermark: undefined,
      resizeMediaUrl: resizeMediaUrl,
    });
    return galleryItem;
  }

  constructMetaTagsItemData(galleryItem, page_url, fullscreen_url) {
    const itemData = {
      item: {
        id: galleryItem.id,
        type: galleryItem.type, // image/video/text
        title: galleryItem.title, // image-title
        description: galleryItem.description, // image-description
        page_url,
        fullscreen_url,
        image: {
          url: galleryItem.createUrl(
            GALLERY_CONSTS.urlSizes.RESIZED,
            GALLERY_CONSTS.urlTypes.SEO,
          ),
          width: galleryItem.width,
          height: galleryItem.height,
        },
        video_url:
          galleryItem.videoUrl ||
          galleryItem.createUrl(
            GALLERY_CONSTS.urlSizes.RESIZED,
            GALLERY_CONSTS.urlTypes.VIDEO,
          ),
        thumbnail: {
          url: galleryItem.createUrl(
            GALLERY_CONSTS.urlSizes.RESIZED,
            GALLERY_CONSTS.urlTypes.LOW_RES,
          ),
          width: 250,
          height: 250,
        },
      },
    };
    return itemData;
  }

  setMetaTagsInSSR(item, page_url, fullscreen_url, api) {
    const galleryItem = this.itemToGalleryItem(item);
    const itemData = this.constructMetaTagsItemData(
      galleryItem,
      page_url,
      fullscreen_url,
    );
    this.setMetaTags(itemData, api);
  }

  setMetaTags(itemData, api) {
    // Setting metaTags for SEO fullscreen navigation
    if (itemData.item) {
      api.seo.renderSEOTags({
        itemType: 'PRO_GALLERY_ITEM',
        itemData,
        asNewPage: true,
      });
    } else {
      api.seo.resetSEOTags();
    }
  }

  setDirectFullscreenItem(item) {
    const { item: resItem, convertedToPlaceholder } = this.addItemProperties(
      item,
    );
    if (convertedToPlaceholder) {
      this.getItemWithVideoUrls(item).then(itemWithVideoUrls => {
        this.setDirectFullscreenItem(itemWithVideoUrls);
      });
    }
    if (resItem.itemId && !resItem.metaData.isVideoPlaceholder) {
      this.directFullscreenItem = resItem;
      this.setDirectfullscreenOnce && this.setDirectfullscreenItemCallBack();
      this.setDirectfullscreenOnce = true;
    }
  }

  getDirectFullscreenItem() {
    return this.directFullscreenItem ? this.directFullscreenItem : false;
  }

  getTestType() {
    const queryParams = this.getQueryParams();
    const pgTestType = queryParams && queryParams.pgTestType;
    if (pgTestType) {
      return pgTestType;
    } else {
      return null;
    }
  }

  getSSRWorkerLog() {
    return this.SSRWorkerLog;
  }

  addItems(items, from) {
    const curItems = this.getItems();
    this.setItems(curItems.slice(0, from).concat(items)); // do not double append items
  }

  loadInitialItems(isSSR) {
    if (utils.isVerbose()) {
      console.log(
        'Getting initial items from server',
        this.items,
        this.totalItemsCount,
      );
    }
    if (this.items && this.items.length > 0) {
      // items were already loaded by wix-code or warmup data
      return Promise.resolve(this.items);
    } else {
      let itemsPromise;
      try {
        itemsPromise = this.getGalleryDataFromServer({
          from: 0,
          batchSize: this.INITIAL_ITEMS_BATCH_SIZE,
          options: { isSSR },
        });
      } catch (e) {
        console.error('couldnt get items', e);
        itemsPromise = Promise.resolve({
          pageResponse: {
            totalItemsCount: 0,
            items: [],
          },
        });
      }

      return itemsPromise.then(response => {
        if (utils.isVerbose()) {
          console.log('Got initial items from server', response);
        }
        try {
          const { galleryId, dateCreated } = response;
          const { items, settings, totalItemsCount } = response.pageResponse;
          Object.assign(this, { galleryId, dateCreated, settings }); // parse everything
          if (this.itemsSrc !== 'wixCode') {
            // do not override wixCode items with items from db
            this.setItems(items);
            this.totalItemsCount = Number(totalItemsCount);
          }
        } catch (e) {
          console.error(
            'Could not fetch initial items from server',
            e,
            response,
          );
          this.totalItemsCount = 1;
        }
        return this.getItems();
      });
    }
  }

  loadInitialDataForArtStore(isSSR) {
    let itemsPromise;
    try {
      itemsPromise = this.getGalleryDataFromServer({
        from: 0,
        batchSize: this.INITIAL_ITEMS_BATCH_SIZE,
        options: { isSSR },
      });
    } catch (e) {
      console.error('couldnt get items', e);
      itemsPromise = Promise.resolve({
        pageResponse: {
          totalItemsCount: 0,
          items: [],
        },
      });
    }

    return itemsPromise;
  }

  loadDirectFullscreen(itemId, isSSR) {
    const directFullscreenItemPromise = this.getItemByIdFromServer(
      itemId,
      isSSR,
    );
    return directFullscreenItemPromise.then(response => {
      const { item } = response;
      if (item && item.id) {
        const formattedItem = serverItemsToProGallery([item]);
        if (formattedItem[0] && formattedItem[0].itemId) {
        }
        this.setDirectFullscreenItem(formattedItem[0]);
      } else {
        if (utils.isVerbose()) {
          console.log(
            'Could not fetch direct fullscreen item from server',
            response,
            '' + this.directFullscreenItem
              ? 'The directFullscreen was loaded from wixCode'
              : '',
          );
        }
      }
      return this.getDirectFullscreenItem();
    });
  }

  pageReady(setProps) {
    this.setProps = setProps;
  }
}
